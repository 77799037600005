import { render } from "./RegisterForm.vue?vue&type=template&id=187a4f9d&scoped=true"
import script from "./RegisterForm.vue?vue&type=script&lang=ts"
export * from "./RegisterForm.vue?vue&type=script&lang=ts"

import "./RegisterForm.vue?vue&type=style&index=0&id=187a4f9d&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-187a4f9d"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QForm,QInput,QIcon,QBtn,QSpace,QSeparator});
