<template>
  <div class="register-form">
    <q-card class="q-pa-lg">
      <q-card-section>
        <div class="text-center">
          <AppLogoOnly />
          <div class="text-h6 q-mt-md">{{ $t('label.register.create_new_account') }}</div>
          <div class="text-subtitle2">{{ $t('label.register.input_your_information_to_register') }}</div>
        </div>
      </q-card-section>

      <q-form ref="formRef">
        <q-input
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || requiredRule.input_your_name]"
          outlined
          v-model="form.display_name"
          dense
          :placeholder="$t('label.register.input_your_name')"
        >
          <template v-slot:prepend>
            <q-icon name="account_circle" />
          </template>
        </q-input>

        <q-input
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || requiredRule.email]"
          outlined
          v-model="form.email"
          dense
          :placeholder="$t('label.register.input_your_email')"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>

        <q-input
          type="password"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || requiredRule.password]"
          outlined
          v-model="form.password"
          dense
          autocomplete="input_your_password"
          :placeholder="$t('label.register.input_your_password')"
        >
          <template v-slot:prepend>
            <q-icon name="vpn_key" />
          </template>
        </q-input>

        <q-input
          type="password"
          lazy-rules
          :rules="[(val) => (val && val.length > 0 && val === form.password) || requiredRule.password_again]"
          outlined
          v-model="form.confirm_password"
          dense
          autocomplete="confirm_your_password"
          :placeholder="$t('label.register.confirm_your_password')"
        >
          <template v-slot:prepend>
            <q-icon name="lock_open" />
          </template>
        </q-input>
        <div class="row no-wrap items-center">
          <q-btn icon="arrow_back" flat color="primary" @click="onGotoLogin"> {{ $t('login') }} </q-btn>
          <q-space />
          <q-btn class="btn-save" @click="onSubmit"> {{ $t('create_account') }}</q-btn>
        </div>

        <q-separator class="q-my-md" />

        <!-- <ButtonLoginGoogle /> -->
      </q-form>
    </q-card>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import AppLogoOnly from '@/components/common/AppLogoOnly.vue'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: {
    AppLogoOnly,
  },
  directives: { maska },
})
export default class RegisterForm extends mixins(LoginRegisterMixin) {
  form = {
    display_name: '',
    email: '',
    password: '',
  }

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  get requiredRule() {
    const requiredRule = {
      input_your_name: this.$t('validate.please_input_your_name'),
      email: this.$t('validate.please_input_your_valid_email'),
      password: this.$t('validate.please_input_your_valid_password'),
      password_again: this.$t('validate.please_input_your_valid_password_again'),
    }
    return requiredRule
  }

  async onSubmit() {
    this.$refs.formRef
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }
        this.doRegister()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doRegister() {
    const { email, display_name, password } = this.form
    const { dispatch } = this.$store
    if (email && password) {
      await dispatch(ACTION_PROFILE.REGISTER, {
        email,
        password,
        display_name,
      }).catch((error: Error) => {
        errorHandler(error)
      })
    }
  }

  gotoHome() {
    this.$router.push({ name: 'home' })
  }

  onGotoLogin() {
    this.$router.push({ name: 'login' })
  }
}
</script>
<style lang="scss" scoped>
.register-form {
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
}
</style>
